
import Vue, { VueConstructor } from "vue";

import IncomeForm from "./forms/IncomeForm.vue";
import CrudMixins from "@/mixins/crud";
import Income from "@/models/income";
import CattleLot from "@/models/cattleLot";
import Ownership from "@/models/ownership";

import { IParams, ISearchResponse } from "@/types/store-types";
import { IFormFuncs, ItemDataTable } from "@/types/form-types";
import { crudService } from "@/services/crud";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CrudMixins>>
).extend({
  components: {
    IncomeForm,
  },
  mixins: [CrudMixins],
  data() {
    return {
      dialog: false,
      income: false,
      ownerships: [] as Ownership[],
      disableAdd: false,
      cattle_lots: [] as CattleLot[],
      results: {} as ISearchResponse<Income>,

      headers: [
        {
          text: "Safra",
          tooltip: "Safra de referência",
          align: "left",
          value: "harvest.name",
        },
        {
          text: "Lote",
          tooltip: "Lote da venda",
          align: "center",
          value: "cattle_lot.name",
        },
        {
          text: "Cultura",
          tooltip: "Cultura",
          align: "center",
          value: "growing.name",
        },
        {
          text: "Comprador",
          tooltip: "Origem da receita",
          align: "center",
          value: "provider.name",
        },
        {
          text: "Quantidade (sc)",
          tooltip: "Nome registrado do talhão",
          align: "center",
          value: "amount",
        },
        {
          text: "Moeda",
          tooltip: "Moeda da transação",
          align: "center",
          value: "currency.name",
        },
        {
          text: "Valor unitário (R$)",
          tooltip: "Valor unitário",
          align: "center",
          value: "price",
        },
        {
          text: "Valor Total (R$)",
          align: "center",
          value: "value",
        },
        {
          text: "Ações",
          align: "center",
          tooltip: "Modificar ou excluir o talhão",
          value: "actions",
          sortable: false,
        },
      ] as ItemDataTable[],
    };
  },
  async mounted() {
    this.localLoading = true;
    this.ownerships = await this.myOwnerships();
    if (this.ownerships.length > 0) {
      this.ownershipSelected = this.ownerships[0].id;
      this.searchIncomes();
    } else {
      this.disableAdd = true;
    }
    // busca todos os lotes
    this.cattle_lots = await this.myLots(true);
  },
  computed: {
    headerFiltered(): ItemDataTable[] {
      if (this.$vuetify.breakpoint.smAndDown) {
        return [
          this.headers[0],
          this.headers[1],
          this.headers[2],
          this.headers[3],
          this.headers[5],
          this.headers[this.headers.length - 1],
        ];
      }
      return this.headers;
    },
    cattleLotsFiltered(): CattleLot[] {
      return this.cattle_lots.filter(
        (ct) => ct.ownership_id === this.ownershipSelected
      );
    },
  },
  methods: {
    async searchIncomes(): Promise<void> {
      this.localLoading = true;
      const params: IParams = {
        ownership_id: this.ownershipSelected,
        checkDelete: true,
      };
      // Busca as receitas
      try {
        this.results = await crudService.getResourceClass(
          Income,
          Income.getResource(),
          params
        );
      } catch (error) {
        this.$alertError("Erro ao carregar as receitas");
      } finally {
        this.localLoading = false;
      }
    },
    edit(uincome: Income): void {
      const form = this.$refs["form"] as IFormFuncs<Income>;
      if (form && form.toUpdate) form.toUpdate(uincome);
    },
    confirmDelete(dincome: Income): void {
      this.setResource("income");
      this.delete(dincome.id)
        .then(() => {
          this.$alertSuccess("Receita deleteda com sucesso!");
          this.removeFromResourceList(this.results.objects, dincome);
        })
        .catch(() => {
          this.$alertError("Houve um problema ao deletar a receita!");
        });
    },
  },
});
